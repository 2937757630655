.attendee-note-modal {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 650px;
    }
  }

  &__content {
    padding: 0 !important;

    textarea{
      min-height: 350px;
    }
  }

  label {
    margin-bottom: 0;
  }
}