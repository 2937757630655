.selected-filters {
  &__btn-wrapper {
    float: right;
  }
  
  &__btn {
    margin: 0;
    padding: 0px 5px;
  }

  td {
    vertical-align: middle;
  }
}