.todo {
  margin-bottom: 15px;
}

.todo__checkbox {

  input:checked ~ .todo__checkbox-label {
    color: $color-additional;
    text-decoration: line-through;
  }

  .todo__checkbox-label {

    @include directify($directions) {
      text-align: #{directed('left')};
    }
  }
}

.todo__checkbox-disabled{
  cursor: default;
  opacity: 0.7;

  .todo__checkbox-label {

    @include directify($directions) {
      text-align: #{directed('left')};
    }
  }
}

.todo-app {

  .no-data {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
    padding-bottom: 20px;
  }

  .todo-app__divider {
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .todo-app__divider-title {
    text-transform: uppercase;
    color: rgba(92, 104, 156, 0.6);
    margin: 0 10px;
  }

  .todo-app__divider-line {
    height: 1px;
    background-color: rgba(92, 104, 156, 0.6);
    opacity: 0.15;
    flex-grow: 1;
  }

  .todo__item {
    display: flex;

    .todo__info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-flow: column;

      @media screen and (min-width: 1280px){
        flex-flow: row;
      }

      .todo__content {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 100%;
        word-break: break-all;

        @media screen and (min-width: 1280px) {
          max-width: 500px;
        }

        @media screen and (min-width: 1440px) {
          max-width: 850px;
        }

        h3 {
          width: 100%;
          max-width: 100%;
          font-size: 16px;
          line-height: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .todo__description {
          width: 100%;
          word-break: break-all;
        }
      }

      .todo__additional-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-self: baseline;
        width: 100%;
        height: 100%;

        @media screen and (min-width: 400px) {
          flex-flow: row;
        }

        @media screen and (min-width: 576px) {
          flex-flow: column;
        }

        @media screen and (min-width: 768px) {
          flex-flow: row;
        }

        @media screen and (min-width: 1280px) {
          flex-flow: column;
          align-items: flex-end;
          width: initial;
        }

        .todo__additional {
          display: flex;
          margin: 0 8px 10px 0;
          white-space: nowrap;

          @include directify($directions) {
            #{directed('margin-left')}: 0;
          }
        }

        .todo__priority {
          display: flex;
          align-self: baseline;

          :not(:last-child) {
            margin-right: 10px;
          }

          @include directify($directions) {
            #{directed('margin-right')}: 0;
          }

          @include themify($themes) {
            color: themed('colorText');
          }
        }

        .todo__priority-indicator {
          background-color: $color-accent;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          align-self: center;
          flex-shrink: 0;

          &.low {
            background-color: $color-accent;
          }

          &.medium {
            background-color: $color-yellow;
          }

          &.high {
            background-color: $color-red;
          }
        }

        .todo__due-date, .todo__priority {

          @include themify($themes) {
            background-color: themed('colorHover');
          }
        }

        .todo__due-date {

          @include directify($directions) {
            #{directed('margin-right')}: 15px;
          }
        }

        .todo__btn-wrapper {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          min-width: 68px;

          :not(:last-child) {
            margin-right: 10px;
          }
        }

        .todo__delete-btn {
          border: none;
          position: relative;
          color: $color-additional;
          cursor: pointer;
          align-self: flex-end;

          @include themify($themes) {
            background-color: themed('colorHover');
          }

          svg {
            height: 16px;
            width: 16px;
            fill: $color-additional;
          }

          &:hover {
            color: $color-accent;

            svg {
              fill: $color-accent;
            }
          }
        }

        .todo__delete-btn--delete {

          &:hover {
            color: $color-red-hover;

            svg {
              fill: $color-red-hover;
            }
          }
        }
      }
    }
  }

  .todo__item-completed {

    h3 {
      text-decoration: line-through;
    }

    h3, .todo__content {
      color: $color-additional;
    }
  }

  .todo__item-archived {
    .todo__header,
    .todo__description,
    .todo__label-checkbox {
      opacity: 0.4;
    }

    .checkbox-indicator {
      cursor: default !important;
    }
  }

  .todo__input-new {
    text-align: center;
  }

  .todo__sidebar {
    background-color: $color-blue;
    min-height: 710px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;

    .todo_sidebar-image {
      width: 130px;
      margin-bottom: 20px;
    }

    .todo__date-filter,
    .todo__priority-filter {
      align-self: flex-start;
      margin-bottom: 20px;

      @include directify($directions) {
        text-align: directed('left');
      }

      .title {
        font-size: 12px;
        margin-bottom: 15px;
        color: white;
        opacity: 0.6;
      }
    }

    .todo__date-filter-list,
    .todo__priority-filter-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li:not(:last-child) {
        margin-bottom: 10px;
      }

      label {
        margin: 0;
        color: white;
        font-size: 12px;
      }
    }

    .todo__filter-radio {
      display: none;
    }

    .todo__filter-radio + label {
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      cursor: pointer;

      @include directify($directions) {
        #{directed('padding-right')}: 0px;
        #{directed('padding-left')}: 25px;
      }
    }

    .todo__filter-radio + label:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      border: 1px solid #fafbfe;
      border-radius: 50%;

      @include directify($directions) {
        #{directed('left')}: 0px;
      }
    }

    .todo__filter-radio + label:after {
      content: "";
      position: absolute;
      top: 3px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $color-hover;
      opacity: 0;
      transition: .2s;

      @include directify($directions) {
        #{directed('left')}: 3px;
      }
    }

    .todo__filter-radio:checked + label:after {
      opacity: 1;
    }
  }

  .todo__btn-add-new {
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    min-width: 130px;
    background-color: $color-blue;
    margin: 0 0 20px 0;
    border: 2px solid white;
    border-radius: 100px;

    &:before {
      background-color: lighten($color-blue, 10%);
    }

    &:hover {
      color: white;
    }

    svg {
      height: 16px;
      width: 16px;
      margin: 0;
      fill: white;
    }
  }

  .todo__label-checkbox {
    position: relative;
    width: 25px;
    flex-shrink: 0;

    @include directify($directions) {
      #{directed('margin-right')}: 10px;
    }

    .todo__complete-toggle {
      display: none;
    }

    .todo__complete-toggle + .checkbox-indicator {
      position: absolute;
      top: 0;
      width: 19px;
      height: 18px;
      border: 1px solid;
      border-color: $color-dusty-white;
      border-radius: 3px;
      display: block;
      cursor: pointer;

      @include directify($directions) {
        #{directed('left')}: 0px;
      }
    }

    .todo__complete-toggle:checked + .checkbox-indicator {
      border-color: $color-accent;
      background-color: $color-accent;
    }

    .todo__complete-toggle + .checkbox-indicator::before,
    .todo__complete-toggle + .checkbox-indicator::after {
      content: "";
      position: absolute;
      opacity: 0;
    }

    .todo__complete-toggle:checked + .checkbox-indicator::before,
    .todo__complete-toggle:checked + .checkbox-indicator::after {
      content: "";
      position: absolute;
      top: 8px;
      width: 15px;
      height: 2px;
      background-color: white;
      opacity: 1;
      transition: 0.2s;

      @include directify($directions) {
        #{directed('left')}: 1px;
      }
    }

    .todo__complete-toggle:checked + .checkbox-indicator::before {
      transform: rotate(45deg);
      top: 9px;
      height: 1px;
      width: 6px;

      @include directify($directions) {
        #{directed('left')}: 2px;
      }
    }

    .todo__complete-toggle:checked + .checkbox-indicator::after {
      transform: rotate(-45deg);
      top: 7px;
      height: 1px;
      width: 10px;

      @include directify($directions) {
        #{directed('left')}: 6px;
      }
    }
  }
}

.todo__panel-refresh {
  max-width: 100%;
  height: 100%;
  max-height: 80px;
  text-align: center;

  @include directify($directions) {
    #{directed('left')}: 0px;
  }

  svg {
    fill: $color-accent;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;

    @include directify($directions) {
      #{directed('left')}: calc(50% - 24px);
    }
  }

  @keyframes refresh {

    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.todo__add-modal {
  max-width: 500px;

  .modal-content {

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }

  .modal__footer {
    justify-content: flex-start;
  }

  .form__form-group {

    .form__form-group-field input,
    .form__form-group-field textarea {

      @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
        color: themed('colorText');
      }
    }

    .form__form-group-field.priority {
      display: block;

      .css-yk16xz-control,
      .css-g1d714-ValueContainer,
      .css-1hb7zxy-IndicatorsContainer,
      .css-4ljt47-MenuList{

        @include themify($themes) {
          background: themed('colorBackgroundBody');
          color: themed('colorHeaderDataPicker');
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  max-width: 100%;
}