.report-filter {
  width: 100%;

  &__input {
    max-width: 400px;
    
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: #4ce1b6;
    }
  }

  &__checkbox-wrapper {
    display: flex;

    label {
      margin: 0;
    }
  }

  &__checkbox {
    margin: 3px 5px 0 0;
    padding: 0;
    position: relative;
  }

  .react-select {
    max-width: 400px;

    &__menu {
      z-index: 9999;
    }
  }

  &__form-component {
    margin: 5px 0;

    button {
      margin-bottom: 0;
    }
  }

  .react-datepicker-popper {
    z-index: 9999;
    left: -50px !important;
    box-shadow: -1px 0px 10px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 0px 10px 0px rgba(0,0,0,0.75);
  }

  &__btn {
    float: right;
  }

  .card {
    padding-bottom: 10px !important;
  }
}