.table {
  margin-top: 50px;

  &__title {
    text-align: center;
  }

  &__bold-cell {
    font-weight: bold;
  }

  &__columns-row {
    height: 60px;
    min-height: 60px;

    th {
      vertical-align: middle !important;
    }
  }
}