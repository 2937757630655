.panel {
  transition: 0.3s;

  &.panel--collapse {
    height: 108px;

    &.panel--divider .panel__title, .panel__title {
      margin-bottom: 0;

      .subhead {
        opacity: 0;
      }
    }

    &.panel--success, &.panel--primary, &.panel--warning, &.panel--danger {
      height: 72px;
    }

    .panel__body {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  &.panel--narrow {

    .panel__title {
      margin-bottom: 0;
    }

  }

  &.panel--divider {

    .panel__title {
      margin-bottom: 15px;
    }

    .panel__content {
      @include directify($directions) {
        text-align: directed('left');
      }
      padding-top: 0px;
      padding-bottom: 40px;

      .tabs--bordered-bottom {
        .nav-item {
          border-bottom: 1px solid #dee2e6;
          .nav-link {
            @include themify($themes) {
              background-color: themed('colorBackground');
              color: themed('colorText');
            }
          }
        }

      }
    }
  }

  &.panel--success, &.panel--primary, &.panel--warning, &.panel--danger {

    .panel__body {
      padding: 0;
    }

    .panel__title {
      @include directify($directions) {
        #{directed('padding-right')}: 40px;
        #{directed('padding-left')}: 30px;
      }
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;

      h5 {
        color: white;
      }
    }

    .panel__btns {
      top: 13px;

      svg {
        fill: $color-white;
      }
    }

    .panel__content {
      @include directify($directions) {
        #{directed('padding-right')}: 35px;
        #{directed('padding-left')}: 30px;
      }
      padding-top: 0px;
      padding-bottom: 40px;
    }
  }

  &.panel--success {

    .panel__title {
      background-color: $color-accent;
    }
  }

  &.panel--danger {

    .panel__title {
      background-color: $color-red;
    }
  }

  &.panel--warning {

    .panel__title {
      background-color: $color-yellow;
    }
  }

  &.panel--primary {

    .panel__title {
      background-color: $color-blue;
    }
  }
}

.panel__refresh {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  @include directify($directions) {
    #{directed('left')}: 0px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    border-radius: 5px;

    @include themify($themes) {
      background-color: themed('colorBackground');
      opacity: 0.8;
    }
  }

  svg {
    fill: $color-accent;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    @include directify($directions) {
      #{directed('left')}: calc(50% - 24px);
    }
  }

  @keyframes refresh {

    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.panel__body {
  position: relative;
  @include directify($directions) {
    #{directed('padding-right')}: 35px;
    #{directed('padding-left')}: 30px;
  }
  padding-top: 30px;
  padding-bottom: 50px;
  transition: height 0.3s;
}

.panel__btns {
  position: absolute;
  top: 30px;
  @include directify($directions) {
    #{directed('right')}: 10px;
  }
}

.panel__btn {
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }

  svg {
    width: 14px;
    height: 14px;
    fill: $color-additional;
  }

  &:focus {
    outline: none;
  }
}

.panel__title {
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: 0.3s;

  h5 {
    font-size: 13px;
  }

  .subhead {
    @include directify($directions) {
      text-align: directed('left');
    }
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s;
  }
}

.panel__label {
  background-color: $color-accent;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 13px;
  padding: 3px 10px;
  @include directify($directions) {
    #{directed('left')}: 100%;
    #{directed('margin-left')}: 10px;
  }
}

.panel__icon {
  @include directify($directions) {
    display: inline-block;
    #{directed('margin-right')}: 5px;
  }
}
